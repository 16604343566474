import React, { useState, useRef, createRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { defineMessage, FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import { Group } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import configs from "../utils/configs";
import IfFeature from "./if-feature";
import Anger from "../assets/models/animations/readyplayerme/male/Anger.glb";
import Clap from "../assets/models/animations/readyplayerme/male/Clap.glb";
import Fear from "../assets/models/animations/readyplayerme/male/Fear.glb";
import Fly from "../assets/models/animations/readyplayerme/male/Fly.glb";
import HandRaised from "../assets/models/animations/readyplayerme/male/HandRaised.glb";
import Idle from "../assets/models/animations/readyplayerme/male/Idle.glb";
import Laugh from "../assets/models/animations/readyplayerme/male/Laugh.glb";
import Run from "../assets/models/animations/readyplayerme/male/Run.glb";
import RunBack from "../assets/models/animations/readyplayerme/male/RunBack.glb";
import RunLeft from "../assets/models/animations/readyplayerme/male/RunLeft.glb";
import RunRight from "../assets/models/animations/readyplayerme/male/RunRight.glb";
import SitEnd from "../assets/models/animations/readyplayerme/male/SitEnd.glb";
import SitStart from "../assets/models/animations/readyplayerme/male/SitStart.glb";
import Sitting from "../assets/models/animations/readyplayerme/male/Sitting.glb";
import Walk from "../assets/models/animations/readyplayerme/male/Walk.glb";
import WalkBack from "../assets/models/animations/readyplayerme/male/WalkBack.glb";
import WalkLeft from "../assets/models/animations/readyplayerme/male/WalkLeft.glb";
import WalkRight from "../assets/models/animations/readyplayerme/male/WalkRight.glb";
import Dance from "../assets/models/animations/readyplayerme/male/Dance.glb";
import Wave from "../assets/models/animations/readyplayerme/male/Wave.glb";
import AngerFemale from "../assets/models/animations/readyplayerme/female/AngerFemale.glb";
import ClapFemale from "../assets/models/animations/readyplayerme/female/ClapFemale.glb";
import FearFemale from "../assets/models/animations/readyplayerme/female/FearFemale.glb";
import FlyFemale from "../assets/models/animations/readyplayerme/female/FlyFemale.glb";
import HandRaisedFemale from "../assets/models/animations/readyplayerme/female/HandRaisedFemale.glb";
import IdleFemale from "../assets/models/animations/readyplayerme/female/IdleFemale.glb";
import LaughFemale from "../assets/models/animations/readyplayerme/female/LaughFemale.glb";
import RunFemale from "../assets/models/animations/readyplayerme/female/RunFemale.glb";
import RunBackFemale from "../assets/models/animations/readyplayerme/female/RunBackFemale.glb";
import RunLeftFemale from "../assets/models/animations/readyplayerme/female/RunLeftFemale.glb";
import RunRightFemale from "../assets/models/animations/readyplayerme/female/RunRightFemale.glb";
import SitEndFemale from "../assets/models/animations/readyplayerme/female/SitEndFemale.glb";
import SitStartFemale from "../assets/models/animations/readyplayerme/female/SitStartFemale.glb";
import SittingFemale from "../assets/models/animations/readyplayerme/female/SittingFemale.glb";
import WalkFemale from "../assets/models/animations/readyplayerme/female/WalkFemale.glb";
import WalkBackFemale from "../assets/models/animations/readyplayerme/female/WalkBackFemale.glb";
import WalkLeftFemale from "../assets/models/animations/readyplayerme/female/WalkLeftFemale.glb";
import WalkRightFemale from "../assets/models/animations/readyplayerme/female/WalkRightFemale.glb";
import DanceFemale from "../assets/models/animations/readyplayerme/female/DancingFemale.glb";
import WaveFemale from "../assets/models/animations/readyplayerme/female/WaveFemale.glb";
import { fetchReticulumAuthenticated } from "../utils/phoenix-utils";
import { upload } from "../utils/media-utils";
import { ensureAvatarMaterial } from "../utils/avatar-utils";

import AvatarPreview from "./avatar-preview";
import styles from "../assets/stylesheets/avatar-editor.scss";
import { Button } from "./input/Button";

// import dropdownArrowUrl from "../assets/images/dropdown_arrow.png";
// import dropdownArrow2xUrl from "../assets/images/dropdown_arrow@2x.png";

const delistAvatarInfoMessage = defineMessage({
  id: "avatar-editor.delist-avatar-info",
  defaultMessage:
    "Other users already using this avatar will still be able to use it, but it will be removed from 'My Avatars' and search results."
});

const maleAnimations = {
  Anger: Anger,
  Clap: Clap,
  Fear: Fear,
  Fly: Fly,
  HandRaised: HandRaised,
  Idle: Idle,
  Laugh: Laugh,
  Run: Run,
  RunBack: RunBack,
  RunLeft: RunLeft,
  RunRight: RunRight,
  SitEnd: SitEnd,
  SitStart: SitStart,
  Sitting: Sitting,
  Walk: Walk,
  Dancing: Dance,
  WalkLeft: WalkLeft,
  WalkBack: WalkBack,
  WalkRight: WalkRight,
  Wave: Wave
};

const femaleAnimations = {
  Anger: AngerFemale,
  Clap: ClapFemale,
  Fear: FearFemale,
  Fly: FlyFemale,
  HandRaised: HandRaisedFemale,
  Idle: IdleFemale,
  Laugh: LaughFemale,
  Run: RunFemale,
  RunBack: RunBackFemale,
  RunLeft: RunLeftFemale,
  RunRight: RunRightFemale,
  SitEnd: SitEndFemale,
  SitStart: SitStartFemale,
  Sitting: SittingFemale,
  Walk: WalkFemale,
  Dancing: DanceFemale,
  WalkLeft: WalkLeftFemale,
  WalkBack: WalkBackFemale,
  WalkRight: WalkRightFemale,
  Wave: WaveFemale
};

const AVATARS_API = "/api/v1/avatars";

const defaultEditors = [
  // TODO This previously contain tryquilt.io.  We should re-evaluate whether these types of editors are still desired,
  // and change the related code accordingly.
];
const useAllowedEditors = true;
const allowedEditors = [
  ...defaultEditors,
  {
    name: "Skindex Editor",
    url: "https://www.minecraftskins.com/skin-editor"
  },
  {
    name: "MinecraftSkins.net Editor",
    url: "https://www.minecraftskins.net/skineditor"
  }
];

const fetchAvatar = async avatarId => {
  const { avatars } = await fetchReticulumAuthenticated(`${AVATARS_API}/${avatarId}`);
  return avatars[0];
};

// GLTFLoader plugin for splitting glTF and bin from glb.
class GLTFBinarySplitterPlugin {
  constructor(parser) {
    this.parser = parser;
    this.gltf = null;
    this.bin = null;
  }

  beforeRoot() {
    const parser = this.parser;
    const { body } = parser.extensions.KHR_binary_glTF;
    const content = JSON.stringify(ensureAvatarMaterial(parser.json));

    this.gltf = new File([content], "file.gltf", {
      type: "model/gltf"
    });
    this.bin = new File([body], "file.bin", {
      type: "application/octet-stream"
    });

    // This plugin just wants to split gltf and bin from glb and
    // doesn't want to start the parse. But glTF loader plugin API
    // doesn't have an ability to cancel the parse. So overriding
    // parser.json with very light glTF data as workaround.
    parser.json = { asset: { version: "2.0" } };
  }

  afterRoot(result) {
    result.files = result.files || {};
    result.files.gltf = this.gltf;
    result.files.bin = this.bin;
  }
}

RPMAvatarEditor.propTypes = {
  avatarId: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  hideDelete: PropTypes.bool,
  debug: PropTypes.bool,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  povThirdPerson: PropTypes.bool,
  updatePov: PropTypes.func
};

function ReadyPlayerMeFrame({
  setAvatar,
  setPreviewGltfUrl,
  setIsReadyPlayerMeAvatarSelected,
  inputFiles,
  setInputFiles,
  setAvatarLoaded,
  onClose,
  setIsAvatarVisible,
  setIsMale
}) {
  const subdomain = "demo"; // Replace with your custom subdomain
  //   const frame = document.getElementById("frame");
  const readyPlayerMeFrame = useRef();
  //   console.log("data", store, scene, onClose);
  //   frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;

  const styles = {
    width: "100vw",
    height: "98vh",
    margin: "0",
    marginTop: "2vh",
    border: "none"
  };

  async function subscribe(event) {
    const json = parse(event);

    if (json?.source !== "readyplayerme") {
      return;
    }

    // Subscribe to all events sent from Ready Player Me once frame is ready
    if (json.eventName === "v1.frame.ready") {
      readyPlayerMeFrame.current.contentWindow.postMessage(
        JSON.stringify({
          target: "readyplayerme",
          type: "subscribe",
          eventName: "v1.**"
        }),
        "*"
      );
    }

    // Get avatar GLB URL
    if (json.eventName === "v1.avatar.exported") {
      setIsReadyPlayerMeAvatarSelected(true);
      setPreviewGltfUrl(json.data.url);
      //   deciding for MALE or FEMALE here
      await axios.get(json.data.url.replace(".glb", ".json")).then(response => {
        console.log("avatar json data");
        console.log(response);
        setIsMale(response.data.outfitGender == "masculine");
      });

      console.log(`Avatar URL: ${json.data.url}`);
      //temp intput files
      const urlToBlob = await fetch(json.data.url + "?morphTargets=none").then(response => response.blob());
      const tempInputFiles = inputFiles;
      tempInputFiles.glb = new File([urlToBlob], "rpm.glb", { type: urlToBlob.type });

      const filesToUpload = ["gltf", "bin", "base_map", "emissive_map", "normal_map", "orm_map", "thumbnail"].filter(
        k => tempInputFiles[k] === null || tempInputFiles[k] instanceof File
      );
      const fileUploads = await Promise.all(filesToUpload.map(f => inputFiles[f] && upload(inputFiles[f])));

      await setAvatar({
        name: "My Avatar from rpm",
        files: fileUploads
          .map((resp, i) => [
            filesToUpload[i],
            resp && [resp.file_id, resp.meta.access_token, resp.meta.promotion_token]
          ])
          .reduce((o, [k, v]) => ({ ...o, [k]: v }), {}),
        base_gltf_url: json.data.url + "?morphTargets=none",
        parent_avatar_listing_id: ""
      });
      await setInputFiles(tempInputFiles);
      setIsAvatarVisible(true);
      //   setAvatar(store, scene, onClose,json.data.url)
      // TODO: SET AVATAR
      //   document.getElementById("avatarUrl").innerHTML = `Avatar URL: ${json.data.url}`;
      // readyPlayerMeFrame.current.hidden = true;
    }

    // Get user id
    if (json.eventName === "v1.user.set") {
      console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
    }
  }

  window.addEventListener("message", subscribe);
  document.addEventListener("message", subscribe);

  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          onClose();
          console.log("cancelled avatar");
        }}
        style={{ height: 30, width: 120, top: 20, left: 10 }}
      >
        Go Back
      </Button>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          height: "98vh",
          position: "absolute",
          top: "50vh"
        }}
      >
        Loading your Avatar
      </div> */}
      <iframe
        style={styles}
        ref={readyPlayerMeFrame}
        allow="camera *; microphone *"
        src={"https://demo.readyplayer.me/avatar?frameApi&clearCache&bodyType=fullbody"}
        // src={"https://demo.readyplayer.me/avatar?frameApi&bodyType=fullbody"}
      />
    </>
  );
}

export { ReadyPlayerMeFrame as ReadyPlayerMe };

function RPMAvatarEditor({ avatarId, onSave, onClose, hideDelete, debug, className, intl, povThirdPerson, updatePov }) {
  const [baseAvatarResults, setBaseAvatarResults] = useState([]);
  const [editorLinks, setEditorLinks] = useState(defaultEditors);
  const [previewGltfUrl, setPreviewGltfUrl] = useState(null);
  const [animations, setAnimations] = useState(maleAnimations);
  const [inputFiles, setInputFiles] = useState({});
  const [avatar, setAvatar] = useState({
    name: "Loading...",
    files: {}
  });
  const [isAvatarVisible, setIsAvatarVisible] = useState(false);
  const [isReadyPlayerMeAvatarSelected, setIsReadyPlayerMeAvatarSelected] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isMale, setIsMale] = useState(true);
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const preview = useRef(null);
  //   const preview = createRef();
  async function initiateAvatar() {
    let newAvatar;
    let newPreviewGltfUrl;
    let newBaseAvatarResults;
    if (avatarId) {
      const fetchedAvatar = await fetchAvatar(avatarId);
      fetchedAvatar.creatorAttribution = (fetchedAvatar.attributions && fetchedAvatar.attributions.creator) || "";
      Object.assign(inputFiles, fetchedAvatar.files);
      //   setState({ avatar, previewGltfUrl: avatar.base_gltf_url });
      newAvatar = fetchedAvatar;
      newPreviewGltfUrl = fetchedAvatar.base_gltf_url;
    } else {
      const { entries } = await fetchReticulumAuthenticated(`/api/v1/media/search?filter=base&source=avatar_listings`);
      const baseAvatarApiResults = entries.map(e => ({ id: e.id, name: e.name, gltfs: e.gltfs, images: e.images }));
      if (baseAvatarApiResults.length) {
        const randomAvatarResult = baseAvatarApiResults[Math.floor(Math.random() * baseAvatarApiResults.length)];
        newBaseAvatarResults = baseAvatarApiResults;
        newAvatar = {
          name: "My RPM",
          files: {},
          base_gltf_url: randomAvatarResult.gltfs.base,
          parent_avatar_listing_id: randomAvatarResult.id
        };
        newPreviewGltfUrl = randomAvatarResult.gltfs.avatar;
      } else {
        newAvatar = {
          name: "My RPM",
          files: {}
        };
      }
    }
    setAvatar(newAvatar);
    // setPreviewGltfUrl(newPreviewGltfUrl);// initially the avatar should not be visible
    setBaseAvatarResults(newBaseAvatarResults);
  }
  useEffect(() => {
    console.log(isMale);
    setAnimations(() => (isMale ? maleAnimations : femaleAnimations));
  }, [isMale]);

  useEffect(() => {
    initiateAvatar();
    console.log("updated component");
    // HACK: FOR TESTING...comment out 224 to 231 in production
    // setAvatar({
    //   name: "My Avatar from rpm",
    //   files: {},
    //   base_gltf_url: "https://models.readyplayer.me/64509f65f7d2de0e82864144.glb",
    //   parent_avatar_listing_id: " 223"
    // });
    // setPreviewGltfUrl("https://models.readyplayer.me/64509f65f7d2de0e82864144.glb");
    // setIsReadyPlayerMeAvatarSelected(true);
  }, []);

  const createOrUpdateAvatar = avatar => {
    return fetchReticulumAuthenticated(
      avatar.avatar_id ? `${AVATARS_API}/${avatar.avatar_id}` : AVATARS_API,
      avatar.avatar_id ? "PUT" : "POST",
      { avatar }
    ).then(({ avatars }) => avatars[0]);
  };

  const loadGltfFiles = async (gltfLoader, path) => {
    return await new Promise((resolve, reject) => {
      gltfLoader.load(path, resolve, undefined, reject);
    });
  };

  const groupGlbAnimations = async (group, animations) => {
    const gltfLoader = new GLTFLoader();

    for (const [name, path] of Object.entries(animations)) {
      const gltf = await loadGltfFiles(gltfLoader, path);
      gltf.animations[0].name = name;
      group.animations.push(gltf.animations[0]);
    }
  };

  const exportedAnimatedAvatar = async (gltfExporter, gltf, group) => {
    return new Promise((resolve, reject) => {
      gltfExporter.parse(
        gltf.scene,
        glb => {
          const blob = new Blob([glb], { type: "application/octet-stream" });
          resolve(URL.createObjectURL(blob));
        },
        console.error,
        { binary: true, animations: group.animations }
      );
    });
  };

  const animateTheAvatar = async inputFileGlbUrl => {
    const gltfLoader = new GLTFLoader();
    const gltfExporter = new GLTFExporter();
    const group = new Group();
    const gltf = await loadGltfFiles(gltfLoader, inputFileGlbUrl);
    group.name = "Armature";
    group.add(gltf.scene);
    console.log(animations);
    await groupGlbAnimations(group, animations);
    const blobCombinedUrl = await exportedAnimatedAvatar(gltfExporter, gltf, group);
    return blobCombinedUrl;
  };

  const uploadAvatar = async e => {
    e.preventDefault();
    //   alert("upload avatar called");
    console.log("upload avatar called");
    setUploading(true);
    // eslint-disable-next-line no-use-before-define
    const animatedBlobUrl = await animateTheAvatar(URL.createObjectURL(inputFiles.glb));
    //   .catch(console.error);
    let newInputFiles = inputFiles;
    // animatedBlobUrl is a blob-url, convert the blob-url to file
    const animatedBlob = await fetch(animatedBlobUrl).then(response => response.blob());
    const animatedGlbFile = new File([animatedBlob], "file.glb", { type: animatedBlob.type });
    newInputFiles.glb = animatedGlbFile;
    console.log("combine animations completed and url is", animatedBlobUrl);
    if (newInputFiles.glb && newInputFiles.glb instanceof File) {
      const gltfLoader = new GLTFLoader().register(parser => new GLTFBinarySplitterPlugin(parser));
      const gltfUrl = URL.createObjectURL(newInputFiles.glb);
      const onProgress = console.log;

      await new Promise((resolve, reject) => {
        // GLTFBinarySplitterPlugin saves gltf and bin in gltf.files
        gltfLoader.load(
          gltfUrl,
          result => {
            console.log("result files", result.files);

            newInputFiles = {
              ...inputFiles,
              ["gltf"]: result.files.gltf,
              ["bin"]: result.files.bin
            };
            resolve(result);
          },
          onProgress,
          reject
        );
      });
      console.log(inputFiles);

      URL.revokeObjectURL(gltfUrl);
    }
    const thumbnail = new File([await preview.current.snapshot()], "thumbnail.png", { type: "image/png" });
    // setInputFiles(prevInputFiles => ({ ...prevInputFiles, thumbnail }));
    newInputFiles = { ...newInputFiles, thumbnail };
    // combine the animations here

    console.log("newInputFiles", newInputFiles);
    const filesToUpload = ["gltf", "bin", "base_map", "emissive_map", "normal_map", "orm_map", "thumbnail"].filter(
      k => newInputFiles[k] === null || newInputFiles[k] instanceof File
    );

    const fileUploads = await Promise.all(filesToUpload.map(f => newInputFiles[f] && upload(newInputFiles[f])));
    const avatarUpload = {
      ...avatar,
      // base_gltf_url: ,
      base_gltf_url: previewGltfUrl,
      parent_avatar_listing_id: "",
      attributions: {
        creator: avatar.creatorAttribution
      },
      files: fileUploads
        .map((resp, i) => [filesToUpload[i], resp && [resp.file_id, resp.meta.access_token, resp.meta.promotion_token]])
        .reduce((o, [k, v]) => ({ ...o, [k]: v }), {})
    };
    console.log("avatar is", avatarUpload);
    await createOrUpdateAvatar(avatarUpload);
    setInputFiles(newInputFiles);
    setUploading(false);

    if (onSave) onSave();
  };

  const deleteAvatar = async e => {
    e.preventDefault();
    await fetchReticulumAuthenticated(`${AVATARS_API}/${avatar.avatar_id}`, "DELETE");
    if (onSave) onSave();
  };
  // Return the gltf for the selected base avatar, the locally modified glb, or the avatar's base_gltf_url
  const getPreviewUrl = (baseSid, newInputFiles) => {
    if (baseSid && baseSid !== "") {
      const avatarResult = baseAvatarResults.find(a => a.id === baseSid);
      if (avatarResult) return avatarResult.gltfs.avatar;
    }

    return newInputFiles.glb ? URL.createObjectURL(newInputFiles.glb) : avatar.base_gltf_url;
  };

  const mapField = (name, label, accept, disabled = false, title) => (
    <div className="file-input-row" key={name} title={title}>
      <label htmlFor={`avatar-file_${name}`}>
        <div className="img-box">
          {avatar.files[name] ? <img src={avatar.files[name]} /> : <FontAwesomeIcon icon={faCloudUploadAlt} />}
        </div>
        <span>{label}</span>
      </label>
      <input
        id={`avatar-file_${name}`}
        type="file"
        accept={accept}
        disabled={disabled}
        onChange={e => {
          const file = e.target.files[0];
          e.target.value = null;
          //   inputFiles[name] = file;
          setInputFiles(prevState => ({
            ...prevState,
            [name]: file
          }));
          URL.revokeObjectURL(avatar.files[name]);
          setAvatar({
            ...avatar,
            files: {
              ...avatar.files,
              [name]: URL.createObjectURL(file)
            }
          });
        }}
      />
      ;
      {avatar.files[name] && (
        <a
          onClick={() => {
            // inputFiles[name] = null;
            setInputFiles(prevState => ({
              ...prevState,
              [name]: null
            }));
            URL.revokeObjectURL(avatar.files[name]);
            setAvatar({
              ...avatar,
              files: {
                ...avatar.files,
                [name]: null
              }
            });
          }}
        >
          <i>
            <FontAwesomeIcon icon={faTimes} />
          </i>
        </a>
      )}
    </div>
  );

  const textField = (name, placeholder, disabled, required) => (
    <div className="text-field-container">
      <label htmlFor={`#avatar-${name}`}>{placeholder}</label>
      <input
        id={`avatar-${name}`}
        type="text"
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        className="text-field"
        value={avatar[name] || ""}
        // onChange={e => setState({ avatar: { ...avatar, [name]: e.target.value } })}
        onChange={e => setAvatar({ ...avatar, [name]: e.target.value })}
      />
    </div>
  );

  useEffect(() => {
    console.log(avatar);
  }, [avatar]);

  useEffect(() => {
    console.log(inputFiles);
  }, [inputFiles]);

  useEffect(() => {
    console.log(previewGltfUrl);
  }, [previewGltfUrl]);

  const handleAvatarUploadChange = (propName, e) => {
    if (e.target.files == null) return;
    // const newInputFiles;
    const file = e.target.files[0];
    console.log("files", file);
    //   setInputFiles(prevState => ({
    //     ...prevState,
    //     ["glb"]: file
    //   }));
    const newInputFiles = { ...inputFiles, ["glb"]: file };
    // eslint-disable-next-line no-constant-condition
    setInputFiles(newInputFiles);

    console.log("new inputFiles", newInputFiles);
    console.log(" inputFiles", inputFiles);
    URL.revokeObjectURL(avatar.files["glb"]);
    setAvatar(() => ({
      ...avatar,
      //   [propName]: "",
      files: {
        // ...avatar.files,
        glb: URL.createObjectURL(file)
      }
    }));
    console.log("avatar", avatar);

    console.log("not setting previewgltful");

    //   setState({
    //     avatar: {
    //       ...avatar,
    //       [propName]: "",
    //       files: {
    //         ...avatar.files,
    //         glb: URL.createObjectURL(file)
    //       }
    //     },
    //     previewGltfUrl: getPreviewUrl("")
    //   });
    e.target.value = null;
    setPreviewGltfUrl(() => getPreviewUrl("", newInputFiles)); //this line isn't changing anything specific, the avatar preview is still there
    setAvatarLoaded(true);
    povThirdPerson ? updatePov() : updatePov();
    // TODO: set here the third person camera and preference

    // const newGlbUrl = URL.createObjectURL(newInputFiles["glb"]);
    // combineAnimations(newGlbUrl, setFile).catch(console.error);
  };

  const selectListingGrid = (propName, placeholder) => (
    <div className="select-grid-container">
      <label htmlFor={`#avatar-${propName}`}>{placeholder}</label>
      <input
        id="avatar-file_glb"
        type="file"
        accept="model/gltf+binary,.glb"
        onChange={e => handleAvatarUploadChange(propName, e)}
      />
      <label htmlFor="avatar-file_glb" className={classNames("item", "custom", { selected: "" === avatar[propName] })}>
        <FormattedMessage
          id="avatar-editor.upload-custom-avatar-button"
          defaultMessage="{icon} Custom GLB"
          values={{ icon: <FontAwesomeIcon icon={faCloudUploadAlt} /> }}
        />
      </label>
    </div>
  );

  const textarea = (name, placeholder, disabled) => (
    <div>
      <textarea
        id={`avatar-${name}`}
        disabled={disabled}
        placeholder={placeholder}
        className="textarea"
        value={avatar[name] || ""}
        onChange={e => setAvatar({ ...avatar, [name]: e.target.value })}
      />
    </div>
  );

  const checkbox = (name, title, children, disabled) => (
    <div className="checkbox-container" title={title}>
      <input
        id={`avatar-${name}`}
        type="checkbox"
        className="checkbox"
        disabled={disabled}
        checked={!!avatar[name]}
        onChange={e => setAvatar({ ...avatar, [name]: e.target.checked })}
      />
      <label htmlFor={`#avatar-${name}`}>{children}</label>
    </div>
  );

  const handleGltfLoaded = gltf => {
    const ext = gltf.parser.json.extensions && gltf.parser.json.extensions["MOZ_hubs_avatar"];
    let editorLinks = (ext && ext.editors) || defaultEditors;
    if (useAllowedEditors) {
      editorLinks = editorLinks.filter(e => allowedEditors.some(w => w.name === e.name && w.url === e.url));
    }
    // setState({ editorLinks });
    setEditorLinks(editorLinks);
  };

  //   render() {
  //     const { debug, intl } = props;
  //     const { avatar } = state;

  return (
    <>
      {!isReadyPlayerMeAvatarSelected ? (
        <>
          <div>
            <FormattedMessage id="rpm-avatar-editor.loading" defaultMessage="Loading Ready Player Avatar" />
          </div>

          <div className={classNames(styles.avatarEditor, className)}>
            <ReadyPlayerMeFrame
              setAvatar={setAvatar}
              inputFiles={inputFiles}
              setPreviewGltfUrl={setPreviewGltfUrl}
              setIsReadyPlayerMeAvatarSelected={setIsReadyPlayerMeAvatarSelected}
              setInputFiles={setInputFiles}
              onClose={onClose}
              setAvatarLoaded={setAvatarLoaded}
              setIsAvatarVisible={setIsAvatarVisible}
              setIsMale={setIsMale}
            />
          </div>
        </>
      ) : (
        <div className={classNames(styles.avatarEditor, className)}>
          {onClose && (
            <a className="close-button" onClick={onClose}>
              <i>
                <FontAwesomeIcon icon={faTimes} />
              </i>
            </a>
          )}
          {!avatar ? (
            <div className="loader">
              <div className="loader-center" />
            </div>
          ) : (
            <form onSubmit={uploadAvatar} className="center">
              {textField("name", "Name", false, true)}
              <div className="split">
                <div className="form-body">
                  {debug &&
                    textField(
                      "avatar_id",
                      intl.formatMessage({ id: "avatar-editor.field.avatar-id", defaultMessage: "Avatar ID" }),
                      true
                    )}
                  {debug &&
                    textField(
                      "parent_avatar_id",
                      intl.formatMessage({
                        id: "avatar-editor.field.parent-avatar-id",
                        defaultMessage: "Parent Avatar ID"
                      })
                    )}
                  {debug &&
                    textField(
                      "parent_avatar_listing_id",
                      intl.formatMessage({
                        id: "avatar-editor.field.parent-avatar-listing-id",
                        defaultMessage: "Parent Avatar Listing ID"
                      })
                    )}
                  {debug &&
                    textarea(
                      "description",
                      intl.formatMessage({
                        id: "avatar-editor.field.description",
                        defaultMessage: "Description"
                      })
                    )}
                  {!avatarId &&
                    selectListingGrid(
                      "parent_avatar_listing_id",
                      intl.formatMessage({
                        id: "avatar-editor.field.model",
                        defaultMessage: "Model"
                      })
                    )}

                  <label>
                    <FormattedMessage id="avatar-editor.skin-section" defaultMessage="Skin" />
                  </label>
                  {mapField(
                    "base_map",
                    intl.formatMessage({
                      id: "avatar-editor.field.base-map",
                      defaultMessage: "Base Map"
                    }),
                    "image/*"
                  )}
                  <details>
                    <summary>
                      <FormattedMessage id="avatar-editor.advanced-section" defaultMessage="Advanced" />
                    </summary>
                    {mapField(
                      "emissive_map",
                      intl.formatMessage({
                        id: "avatar-editor.field.emissive-map",
                        defaultMessage: "Emissive Map"
                      }),
                      "image/*"
                    )}
                    {mapField(
                      "normal_map",
                      intl.formatMessage({
                        id: "avatar-editor.field.normal-map",
                        defaultMessage: "Normal Map"
                      }),
                      "image/*"
                    )}
                    {mapField(
                      "orm_map",
                      intl.formatMessage({
                        id: "avatar-editor.field.orm-map",
                        defaultMessage: "ORM Map"
                      }),
                      "image/*",
                      false,
                      intl.formatMessage({
                        id: "avatar-editor.field.orm-map-info",
                        defaultMessage: "Occlussion (r), Roughness (g), Metallic (b)"
                      })
                    )}
                  </details>

                  <GenderButtons setIsMale={setIsMale} isMale={isMale} />
                  <label>
                    <FormattedMessage id="avatar-editor.share-settings" defaultMessage="Share Settings" />
                  </label>
                  {checkbox(
                    "allow_promotion",
                    intl.formatMessage(
                      {
                        id: "avatar-editor.field.allow-promotion",
                        defaultMessage: "Allow {companyName} to promote your avatar, and show it in search results."
                      },
                      { companyName: configs.translation("company-name") }
                    ),
                    <span>
                      <FormattedMessage
                        id="avatar-editor.field.allow-promotion-checkbox"
                        defaultMessage="Allow <a>Promotion</a>"
                        values={{
                          a: chunks => (
                            <a
                              href={configs.link(
                                "promotion",
                                "https://github.com/mozilla/hubs/blob/master/PROMOTION.md"
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {chunks}
                            </a>
                          )
                        }}
                      />
                    </span>
                  )}
                  {checkbox(
                    "allow_remixing",
                    intl.formatMessage({
                      id: "avatar-editor.field.allow-remixing",
                      defaultMessage: "Allow others to edit and re-publish your avatar as long as they give you credit."
                    }),
                    <span>
                      <FormattedMessage
                        id="avatar-editor.field.allow-remixing-checkbox"
                        defaultMessage="Allow <a>Remixing</a> <license>(under <licenselink>CC-BY 3.0</licenselink>)</license>"
                        values={{
                          a: chunks => (
                            <a
                              href={configs.link("remixing", "https://github.com/mozilla/hubs/blob/master/REMIXING.md")}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {chunks}
                            </a>
                          ),
                          license: chunks => <span className="license">{chunks}</span>,
                          licenselink: chunks => (
                            <a
                              href="https://creativecommons.org/licenses/by/3.0/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {chunks}
                            </a>
                          )
                        }}
                      />
                    </span>
                  )}
                  {textField(
                    "creatorAttribution",
                    intl.formatMessage({
                      id: "avatar-editor.field.creator-attribution",
                      defaultMessage: "Attribution (optional)"
                    }),
                    false,
                    false
                  )}
                  {/* {mapField("ao_map", "AO Map", "images/\*", true)} */}
                  {/* {mapField("metallic_map", "Metallic Map", "image/\*", true)} */}
                  {/* {mapField("roughness_map", "Roughness Map", "image/\*", true)} */}
                </div>
                {/* {console.log("Avatar preview ", previewGltfUrl)} */}
                <AvatarPreview
                  className="preview"
                  avatarGltfUrl={previewGltfUrl}
                  onGltfLoaded={handleGltfLoaded}
                  {...inputFiles}
                  //   ref={preview => (previewRef = preview)}
                  ref={p => (preview.current = p)}
                  //   forwardedRef={p => console.log("p", p)}
                  // ref={p => (preview = p)}
                />
              </div>
              <div className="info">
                <IfFeature name="show_avatar_editor_link">
                  <p>
                    <FormattedMessage
                      id="avatar-editor.external-editor-info"
                      defaultMessage="Create a custom skin for this avatar:"
                    />{" "}
                    {editorLinks.map(({ name, url }) => (
                      <a
                        key={name}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={url.replace("$AVATAR_GLTF", encodeURIComponent(previewGltfUrl))}
                      >
                        {name}
                      </a>
                    ))}
                  </p>
                </IfFeature>
                <IfFeature name="show_avatar_pipelines_link">
                  <p>
                    <FormattedMessage
                      id="avatar-editor.info"
                      defaultMessage="Find more custom avatar resources <a>here</a>"
                      values={{
                        a: chunks => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://github.com/MozillaReality/hubs-avatar-pipelines"
                          >
                            {chunks}
                          </a>
                        )
                      }}
                    />
                  </p>
                </IfFeature>
              </div>
              <div>
                {isAvatarVisible && (
                  <button className="form-submit" type="submit">
                    {uploading ? (
                      <FormattedMessage id="avatar-editor.submit-button.uploading" defaultMessage="Uploading..." />
                    ) : (
                      <FormattedMessage id="avatar-editor.submit-button.save" defaultMessage="Save" />
                    )}
                  </button>
                )}
              </div>
              {!hideDelete && (
                <div className="delete-avatar">
                  {confirmDelete ? (
                    <span>
                      <FormattedMessage
                        id="avatar-editor.delete-avatar.confirmation-prompt"
                        defaultMessage="Are you sure?"
                      />{" "}
                      <a onClick={deleteAvatar}>
                        <FormattedMessage id="avatar-editor.delete-avatar.confirm" defaultMessage="yes" />
                      </a>{" "}
                      /{" "}
                      <a onClick={() => setConfirmDelete(false)}>
                        <FormattedMessage id="avatar-editor.delete-avatar.cancel" defaultMessage="no" />
                      </a>
                    </span>
                  ) : (
                    <a
                      onClick={() => setConfirmDelete(true)}
                      title={avatar.has_listings ? intl.formatMessage(delistAvatarInfoMessage) : ""}
                    >
                      {avatar.has_listings ? (
                        <FormattedMessage id="avatar-editor.delist-avatar-button" defaultMessage="Delist Avatar" />
                      ) : (
                        <FormattedMessage id="avatar-editor.delete-avatar-button" defaultMessage="Delete Avatar" />
                      )}
                    </a>
                  )}
                </div>
              )}
            </form>
          )}
        </div>
      )}
    </>
  );
}

const GenderButtons = ({ setIsMale, isMale }) => {
  return (
    <div className={styles.genderButtons}>
      {/* {isMale ? ( */}
      <button
        className={`gender-button ${isMale ? "active" : ""}`}
        onClick={e => {
          e.preventDefault();
          setIsMale(true);
        }}
      >
        <FormattedMessage id="avatar-editor.male" defaultMessage="Male" />
      </button>
      {/* ) : ( */}
      <button
        className={`gender-button ${!isMale ? "active" : ""}`}
        onClick={e => {
          e.preventDefault();
          setIsMale(false);
        }}
      >
        <FormattedMessage id="avatar-editor.female" defaultMessage="Female" />
      </button>
      {/* )} */}
    </div>
  );
};

export default injectIntl(RPMAvatarEditor);
