import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./CoinCollectionElement.scss";
import CoinImage from "../../../assets/images/custom-icons/coin.png";

const CoinCollectionElement = ({ setCoinsValue, coinsValue }) => {
  return (
    <div className={styles.coinCollectionElement}>
      <div className={styles.container}>
        {/* {coinsValue.map((coin, index) => ( */}
        <div
          // ref={el => (coinRefs.current[index] = el)}
          // key={index}
          className={styles.coin}
          // onClick={() => setCoinsValue(prev => prev - 1)}
        >
          <img src={CoinImage} className={styles.coinImage} alt="" />
          <>{coinsValue}</>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
};

CoinCollectionElement.propTypes = {
  setCoinsValue: PropTypes.func.isRequired,
  coinsValue: PropTypes.number.isRequired
};

export default CoinCollectionElement;
