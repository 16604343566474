import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./VideoPlayingElement.scss";

const VideoPlayingElement = ({ videoSrc, toggleVideoPlaying, videoTitle }) => {
  const videoRef = useRef();
  const [askedForThali, setAskedForThali] = useState(false);
  const [videoSource, setVideoSource] = useState(videoSrc);
  const [showModal, setShowModal] = useState(true); // Add this line

  useEffect(() => {
    const handleEscapeKeyPress = event => {
      if (event.key === "Escape") {
        setShowModal(false);
      }
    };

    window.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, []);

  useEffect(() => {
    if (videoSource && videoRef.current) {
      videoRef.current.src = videoSource;
      videoRef.current.play();
    }
  }, [askedForThali, videoSource]);

  function playVideoOnYes() {
    setAskedForThali(true);
    setShowModal(false);
  }

  function closeModal() {
    toggleVideoPlaying();
  }

  return (
    <>
      {showModal ? (
        <div className={styles.outerBody}>
          <div className={styles.modalContainer}>
            <div className={styles.modalQuestion}>{videoTitle.question}</div>
            <div className={styles.modalButtonContainer}>
              <div className={styles.modalButton} onClick={playVideoOnYes}>
                {videoTitle.answerText}
              </div>
            </div>
            <div className={styles.closeButton} onClick={closeModal}>
              X
            </div>
          </div>
        </div>
      ) : null}
      {askedForThali && videoSource ? (
        <>
          <video
            ref={videoRef}
            onEnded={toggleVideoPlaying}
            src={videoSource}
            className={styles.fullScreenVideo}
            playsInline
            //   autoPlay
            //   muted
            //   disablePictureInPicture
          />
        </>
      ) : null}
    </>
  );
};

VideoPlayingElement.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  toggleVideoPlaying: PropTypes.func.isRequired,
  videoTitle: PropTypes.object.isRequired
};

export default VideoPlayingElement;
