import React, { useEffect } from "react";
import styles from "./StepsToExplore.scss";
import stepsDesktop from "../../../assets/images/poster.jpg";
import stepsMobile from "../../../assets/images/poster.jpg";
import configs from "../../../utils/configs";

const StepsToExplore = ({ setIsStepsToExploreOpen }) => {
  const isSmallScreen = window.innerWidth <= 400;

  useEffect(() => {
    setTimeout(() => {
      setIsStepsToExploreOpen(false);
    }, 5000);
  }, []);

  return (
    <div className={styles.view} onClick={() => setIsStepsToExploreOpen(false)}>
      <div className={styles.main}>
        {isSmallScreen ? (
          <>
            <img
              src={configs.image("mobile_steps")}
              //   src={stepsMobile}
              style={{
                width: "100%",
                height: "70vh",
                background: "transparent",
                objectFit: "contain",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0
              }}
            />
          </>
        ) : (
          <img
            // src={stepsDesktop}
            src={configs.image("desktop_steps")}
            style={{
              width: "100%",
              height: "70vh",
              background: "transparent",
              objectFit: "contain",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0
            }}
          />
        )}
      </div>
    </div>
  );
};

StepsToExplore.propTypes = {};

export default StepsToExplore;
