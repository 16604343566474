import React from "react";
import styles from "./SelfieModalContainer.scss";
import { ReactComponent as WhatsappIcon } from "../../icons/Whatsapp.svg";
import { ReactComponent as TwitterIcon } from "../../icons/Twitter.svg";
// import { ReactComponent as WhatsappIcon } from "../icons/.svg";
import PropTypes from "prop-types";

const SelfieModalContainer = ({ setScreenshotImage, screenshotImage, whatsappButtonUrl, twitterUrl }) => {
  //   const screenshotImage = "https://abpverse-s3.s3.amazonaws.com/avatars/SELFIES/selfie.jpeg";
  function closeModal() {
    setScreenshotImage(null);
  }
  return (
    <div className={styles.socialMediaModal}>
      <img src={screenshotImage} alt="Share Image" className={styles.shareImage} />
      <div className={styles.socialMediaButtons}>
        <a rel="noopener noreferrer" target="_blank" className={styles.socialMediaButton} href={whatsappButtonUrl}>
          <WhatsappIcon />
        </a>
        <a rel="noopener noreferrer" target="_blank" className={styles.socialMediaButton} href={twitterUrl}>
          <TwitterIcon />
        </a>
        {/* <a rel="noopener noreferrer" target="_blank" className={styles.socialMediaButton}>
          IG
        </a> */}
      </div>
      <div alt="Continue" className={styles.closeIcon} onClick={closeModal}>
        CLOSE
      </div>
    </div>
  );
};

SelfieModalContainer.propTypes = {
  setScreenshotImage: PropTypes.func.isRequired,
  screenshotImage: PropTypes.string.isRequired,
  whatsappButtonUrl: PropTypes.string.isRequired,
  twitterUrl: PropTypes.string.isRequired

};

export default SelfieModalContainer;
