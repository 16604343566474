import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./RoomLayout.scss";
// import { ReactComponent as WhatsappIcon } from "../icons/Whatsapp.svg";
import { Toolbar } from "./Toolbar";
import { FormattedMessage } from "react-intl";
// import { Button } from "../home/Button";
// import { ReactComponent as EyeIcon } from "../icons/Show.svg";
// import Store from "../../storage/store";
// import XLogo from "../../assets/images/x-logo.png";
import configs from "../../utils/configs";
import SelfieModalContainer from "../room/custom-components/SelfieModalContainer";
import ProfileSwipeModal from "../room/custom-components/ProfileSwipeModal";
import axios from "axios";
import { IframeContainer } from "../room/custom-components/IframeContainer";
import VideoPlayingElement from "../room/custom-components/VideoPlayingElement";
import StepsToExplore from "../room/custom-components/StepsToExplore";
import CoinCollectionElement from "../room/custom-components/CoinCollectionElement";

// profile_swipe;
// "iframe_open"
// "iframe_api"
// "backend_api"
// "play_2d_audio"
// "capture_selfie"
// change hub
// read bot text
// video_full_screen

// const tweetPages = {
//   "650b19c042a7f295840f3d3b": "selfie1m",
//   "650b190f42a7f295840f3b91": "selfie2m",
//   "650b18855ed786c8db962bb1": "selfie3m",
//   "650b1b2c5ed786c8db963315": "selfie1fe",
//   "650b1c924e87904620bced46": "selfie2fe",
//   "650b1a335ed786c8db962fb9": "selfie3fe"
// };

export function RoomLayout({
  className,
  viewportClassName,
  sidebar,
  sidebarClassName,
  toolbarLeft,
  toolbarCenter,
  toolbarRight,
  toolbarClassName,
  modal,
  viewport,
  objectFocused,
  streaming,
  viewportRef,
  //   screenshotImage,
  //   setScreenshotImage,
  scene,
  ...rest
}) {
  // const store = new Store();
  const qs = new URLSearchParams(location.search);

  const [showStepsToExplore, setShowStepsToExplore] = useState(false);
  //   const [avatarUrl, setAvatarUrl] = useState(null);
  //   const [visitorCounter, setVisitorCounter] = useState(0);
  const [whatsappButtonUrl, setWhatsappButtonUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  //   const [timer, setTimer] = useState("");

  //iframes
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(undefined);

  const [isProfileSwipeModalOpen, setIsProfileSwipeModalOpen] = useState(false);
  //   const [audioVisible, setAudioVisible] = useState(false);

  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(undefined);
  const [screenshotImage, setScreenshotImage] = useState(undefined);
  // ********************* v i d e o **************************
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [coinsValue, setCoinsValue] = useState(0);
  //-##############################################################
  // const isMobile = window.innerWidth <= 400;

  function endAudioPlaying() {
    console.log("audio ended now playing");
    setAudioPlaying(false);
  }
  function openTheIframe(data) {
    console.log(data);
    setIsIframeVisible(true);
    setIframeUrl(data.detail);
  }

  function toggleIframe() {
    setIsIframeVisible(isIframeVisible => (isIframeVisible ? false : true));
  }

  function toggleVideoPlaying() {
    setIsVideoPlaying(isVideoPlaying => (isVideoPlaying ? false : true));
  }
  function play2dAudio(data) {
    setAudioPlaying(false);
    console.log(data);
    setAudioUrl(data.detail);
    setAudioPlaying(true);
  }
  function profileSwipeOpen() {
    setIsProfileSwipeModalOpen(true);
  }
  async function openTheFrameApi(data) {
    // if it's netlify link coming from api then use it here
    const finalData = await axios.get(data.detail.url);
    console.log(finalData);
    console.log(finalData.data);
    const otherData = await axios.get(data.detail.type);
    console.log(otherData.data);
    setIsIframeVisible(false);
    //   finalData.body is logged as ReadableStream { locked: true }
    setIframeUrl(finalData.data);
  }
  function modalBeforeVideo(data) {
    toggleVideoPlaying();
    setVideoTitle({ question: data.detail.question, answerText: data.detail.answerText ?? "OK" });
    setVideoUrl(data.detail.videoUrl);
    console.log("video fullscreen");
  }
  function captureSelfie() {
    const avatarUrl = qs.get("avatarId");
    const tweetButtonText =
      "https://twitter.com/intent/tweet?text=" +
      encodeURIComponent(configs.translation("tweet-text")) +
      "&url=" +
      encodeURIComponent(configs.translation("tweet-netlify-link"));
    const whatsappButtonUrl =
      "https://api.whatsapp.com/send?text=" + encodeURIComponent(configs.translation("tweet-text"));

    setTwitterUrl(tweetButtonText);
    setWhatsappButtonUrl(whatsappButtonUrl);
    setScreenshotImage(
      "https://" + configs.translation("avatar-s3-bucket") + ".s3.amazonaws.com/" + configs.translation("selfie-file")
    );
  }

  function backendApi(data) {
    console.log(data.detail.endpoint);
  }

  function valueUpdate(data) {
    let value = localStorage.getItem(data.detail.name);
    if (typeof value == "string") {
      value = parseInt(value);
    }
    value = ++value;
    localStorage.setItem(data.detail.name, value);
    // if(data.detail.type == "decrement"){
    //     localStorage.setItem(data.detail.name, value--)
    // }
    setCoinsValue(value);
  }

  useEffect(() => {
    scene.addEventListener("profile_swipe", profileSwipeOpen);
    scene.addEventListener("iframe_open", openTheIframe);
    scene.addEventListener("iframe_api", openTheFrameApi);
    scene.addEventListener("backend_api", backendApi);
    scene.addEventListener("modal_before_video", modalBeforeVideo);
    scene.addEventListener("play_2d_audio", play2dAudio);
    scene.addEventListener("capture_selfie", captureSelfie);
    scene.addEventListener("value_update", valueUpdate);
    scene.addEventListener("2d_popup", play2dAudio);
    scene.addEventListener("read_bot_text", play2dAudio);
    // this has only got it to be working but nothing really has been fixed here
    scene.addEventListener("video_full_screen", modalBeforeVideo);
    let valueOfCoin = localStorage.getItem("coin");
    if (valueOfCoin == 0) {
      valueOfCoin = 1;
    }
    setCoinsValue(valueOfCoin);
    //refer to code for other events below this file
  }, []);

  return (
    <div
      ref={viewportRef}
      className={classNames(styles.roomLayout, { [styles.objectFocused]: objectFocused }, className)}
      {...rest}
    >
      <div className={styles.modalContainer}>
        {screenshotImage && (
          <SelfieModalContainer
            setScreenshotImage={setScreenshotImage}
            screenshotImage={screenshotImage}
            whatsappButtonUrl={whatsappButtonUrl}
            twitterUrl={twitterUrl}
          />
        )}
        {showStepsToExplore && <StepsToExplore setIsStepsToExploreOpen={setShowStepsToExplore} />}
        {isProfileSwipeModalOpen && <ProfileSwipeModal setIsProfileSwipeModalOpen={setIsProfileSwipeModalOpen} />}
        {isIframeVisible && (
          <IframeContainer toggleIframeOpen={toggleIframe} src={iframeUrl} isIframeForMobile={false} />
        )}
        {isVideoPlaying && (
          <VideoPlayingElement toggleVideoPlaying={toggleVideoPlaying} videoSrc={videoUrl} videoTitle={videoTitle} />
        )}
        {coinsValue && coinsValue > 0 && (
          <CoinCollectionElement setCoinsValue={setCoinsValue} coinsValue={coinsValue} />
        )}
      </div>

      {audioPlaying && (
        <div className={styles.hiddenSound}>
          <audio autoPlay onEnded={endAudioPlaying}>
            <source src={audioUrl} type="audio/mpeg" />
            <FormattedMessage
              id="custom-events.audio-not-supported"
              defaultMessage="Your browser does not support the audio."
            />
          </audio>
        </div>
      )}
      {sidebar && <div className={classNames(styles.sidebar, sidebarClassName)}>{sidebar}</div>}
      <div className={classNames(styles.modalContainer, styles.viewport)}>{modal}</div>
      {(((toolbarLeft || toolbarCenter || toolbarRight) && configs.feature("show-toolbar")) || qs.get("inspect")) && (
        <Toolbar
          className={classNames(styles.main, styles.toolbar, toolbarClassName)}
          left={toolbarLeft}
          center={toolbarCenter}
          right={toolbarRight}
        />
      )}
      <div className={classNames(styles.main, styles.viewport, { [styles.streaming]: streaming }, viewportClassName)}>
        {viewport}
      </div>
    </div>
  );
}

RoomLayout.propTypes = {
  className: PropTypes.string,
  viewportClassName: PropTypes.string,
  entered: PropTypes.bool,
  toggleIFrame: PropTypes.func,
  sidebar: PropTypes.node,
  sidebarClassName: PropTypes.string,
  toolbarLeft: PropTypes.node,
  toolbarCenter: PropTypes.node,
  toolbarRight: PropTypes.node,
  toolbarClassName: PropTypes.string,
  modal: PropTypes.node,
  viewport: PropTypes.node,
  objectFocused: PropTypes.bool,
  streaming: PropTypes.bool,
  viewportRef: PropTypes.any,
  scene: PropTypes.any
};

// scene.addEventListener("confirm_for_selfie", async function (data) {});
// scene.addEventListener("capture_selfie", function (data) {
//   const qs = new URLSearchParams(location.search);
//   const avatarUrl = qs.get("avatarId");
//   //   setScreenshotImage("https://mp-bucket-aws.s3.com/SELFIE/test.png" + selfies[avatarUrl]);
//   //   setScreenshotImage("https://mp-bucket-aws.s3.amazonaws.com/SELFIE/test.png");
//   setScreenshotImage(
//     "https://" +
//       (configs.translation("avatar-s3-bucket") ?? "") +
//       //   "abp-metaverse-bucket" +
//       ".s3.amazonaws.com/" +
//       (configs.translation("selfie-file") == null ? "ABP_Group_selfie.jpg" : configs.translation("selfie-file"))
//   );
// });
// scene.addEventListener("open_link", function (data) {
//   window.open(data.detail, "_blank");
// });
// scene.addEventListener("modal_before_video", function (data) {
//   toggleVideoPlaying(data.detail.videoUrl);
//   setVideoTitle({ question: data.detail.question, answerText: data.detail.answerText });
// });
// scene.addEventListener("video_full_screen", function (data) {
//   setVideoTitle(data.detail.question);
//   toggleVideoPlaying(data.detail.videoUrl);
// });

// profile_swipe;
// "iframe_open"
// "iframe_api"
// "backend_api"
// "play_2d_audio"
// "capture_selfie"
// change hub
// read bot text
// video_full_screen
