/**
 * Creates a box around the element (assumed to be the camera's PoV) which can be used for fade-to-black.
 */

import faderImage from "../assets/images/fader.png";
import HubsTextureLoader from "../loaders/HubsTextureLoader";
const FADE_DURATION_MS = 750;

AFRAME.registerComponent("fader", {
  schema: {
    direction: { type: "string", default: "none" } // "in", "out", or "none"
  },

  init() {
    // Load the image texture
    const textureLoader = new HubsTextureLoader();
    const texture = textureLoader.load(faderImage);
    texture.encoding = THREE.sRGBEncoding;
    texture.flipY = false;
    // textureLoader.load(
    //   faderImage,
    //   texture => {
    //     console.log("Texture loaded successfully.---- hrithik");
    //     // Proceed with applying the texture to the material
    //   },
    //   undefined,
    //   error => {
    //     console.error("Error loading texture: --- hrithik ", error);
    //   }
    // );
    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshBasicMaterial({
      // color: 0x000, if we add color it overrides the images
      side: THREE.DoubleSide,
      map: texture,
      opacity: 1,
      transparent: false,
      fog: false
    });

    const mesh = new THREE.Mesh(geometry, material);
    mesh.scale.x = mesh.scale.y = 1;
    mesh.scale.z = 0.5;
    mesh.matrixNeedsUpdate = true;
    this.el.object3DMap.camera.add(mesh);
    this.mesh = mesh;
  },

  fadeOut() {
    return this.beginTransition("out");
  },

  fadeIn() {
    return this.beginTransition("in");
  },

  async beginTransition(direction) {
    if (this._resolveFinish) {
      throw new Error("Cannot fade while a fade is happening.");
    }

    this.el.setAttribute("fader", { direction });

    return new Promise(res => {
      if (this.mesh.material.opacity === (direction == "in" ? 0 : 1)) {
        res();
      } else {
        this._resolveFinish = res;
      }
    });
  },

  tick(t, dt) {
    const mat = this.mesh.material;
    this.mesh.visible = this.data.direction === "out" || mat.opacity !== 0;
    if (!this.mesh.visible) return;

    if (this.data.direction === "in") {
      mat.opacity = Math.max(0, mat.opacity - (1.0 / FADE_DURATION_MS) * Math.min(dt, 50));
    } else if (this.data.direction === "out") {
      mat.opacity = Math.min(1, mat.opacity + (1.0 / FADE_DURATION_MS) * Math.min(dt, 50));
    }

    if (mat.opacity === 0 || mat.opacity === 1) {
      if (this.data.direction !== "none") {
        if (this._resolveFinish) {
          this._resolveFinish();
          this._resolveFinish = null;
        }
      }

      this.el.setAttribute("fader", { direction: "none" });
    }
  }
});
