import React, { useRef } from "react";
import PropTypes from "prop-types";
import { RoomLayout } from "../layout/RoomLayout";
import { useResizeViewport } from "./hooks/useResizeViewport";

export function RoomLayoutContainer({ store, scene, entered, ...rest }) {
  const viewportRef = useRef();

  useResizeViewport(viewportRef, store, scene);

  return <RoomLayout scene={scene} entered={entered} viewportRef={viewportRef} {...rest} />;
}

RoomLayoutContainer.propTypes = {
  store: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  entered: PropTypes.bool
};
